import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import Favicon from './Favicon';
import Facebook from './Facebook';
import Twitter from './Twitter';

type Props = {
  title?: string;
  description?: string;
  image?: string;
};

// https://www.gatsbyjs.com/docs/add-seo-component/
export const Seo: React.FC<Props> = ({ title, description, image }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`
  };

  return (
    <>
      <Favicon />
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
        <meta
          name="google-site-verification"
          content="tiJxHU9K9gSGAXRQIOcC_TPQAHbcr7kbGTHxvk91VOg"
        />
        <meta
          name="google-site-verification"
          content="vXQ26bclX9oyYHhFbxJ2MTllcVLr-wHQHc9-RPOvlYQ"
        />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
      </Helmet>
      <Facebook
        pageUrl={seo.url}
        isArticle={true}
        title={seo.title}
        description={seo.description}
        image={seo.image}
      />
      <Twitter
        username={twitterUsername}
        title={seo.title}
        description={seo.description}
        image={seo.image}
      />
    </>
  );
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`;
