import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { navigationPath } from '../../../config';

const NavList = styled.ul`
  display: flex;
  list-style: none;
  font-size: 1.1rem;
`;

const NavItem = styled.li`
  a {
    color: #5f6f81;
    padding: 0.5rem 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  /* a:hover {
    background-color: #304cfd;
    border-radius: 14px;
    font-weight: 600;
    font-size: 16px;
    color: #93a5b1;
  } */
`;

export const PcNav = () => (
  <nav>
    <NavList>
      {navigationPath.map(menu => {
        return (
          <NavItem key={menu.title}>
            <Link to={menu.path}>{menu.title}</Link>
          </NavItem>
        );
      })}
    </NavList>
  </nav>
);
