import styled from 'styled-components';
import { mediaQueryTresholds } from '../../../config';

export const Wrapper = styled.main`
  min-height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #edf2f7;

  /* PC */
  @media screen and (min-width: ${mediaQueryTresholds.PC}px) {
    padding: 1rem;
  }
`;
