import React, { useEffect, useState } from 'react';
import GlobalStyle from '../../globalStyle';
import getIsSmartPhone from '../../utils/getIsSmartPhone';
import { Seo } from '../SEO';
import { Header } from './Header';
import { Wrapper } from './Wrapper';

type Props = {
  seoProps?: {
    title?: string;
    description?: string;
    image?: string;
  };
};

export const ScreenContext = React.createContext(null);

export const Layout: React.FC<Props> = ({ children, seoProps }) => {
  const [isSmartPhone, setIsSmartPhone] = useState<boolean | null>(null);

  const handleDeviceSize = () => {
    setIsSmartPhone(getIsSmartPhone());
  };

  useEffect(() => {
    handleDeviceSize();
    window.addEventListener('resize', handleDeviceSize);

    return () => {
      window.addEventListener('resize', handleDeviceSize);
    };
  }, []);

  return (
    <ScreenContext.Provider value={isSmartPhone}>
      <GlobalStyle />
      <Seo {...seoProps} />
      <Header />
      <Wrapper>{children}</Wrapper>
    </ScreenContext.Provider>
  );
};
