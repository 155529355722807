import * as React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout } from '../components/PageLayout';
import { PostCard } from '../components/PostCard';

const PostContainer = styled.ol`
  max-width: 1200px;
  min-width: 60vw;
  height: 100%;
  margin: 0;
  padding: 0 3vw;
`;

export default ({ data }) => {
  const posts = data?.allMarkdownRemark?.nodes || [];

  return (
    <>
      <Layout>
        {!posts.length ? (
          <p>No blog posts found.</p>
        ) : (
          <PostContainer>
            {posts.map(post => (
              <PostCard
                key={post.fields.slug}
                title={post.frontmatter.title}
                slug={post.fields.slug}
                cover={post.frontmatter.cover?.childImageSharp?.resize.src}
              />
            ))}
          </PostContainer>
        )}
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { ne: null }, isShowMainPage: { eq: "true" } }
      }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          cover {
            childImageSharp {
              resize(width: 70) {
                src
              }
            }
          }
        }
      }
    }
  }
`;
