import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { mediaQueryTresholds } from '../../config';

const PostCardItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  height: 80px;

  @media (min-width: ${mediaQueryTresholds.SP}px) {
    padding: 1rem 10px;
  }
  @media (min-width: ${mediaQueryTresholds.PC}px) {
    padding: 1.5rem 10px;
  }

  a {
    color: rgba(0, 0, 0, 0.65);
    text-decoration: none;
  }
  p {
    color: #5f6368;
  }
`;

const Article = styled.article`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`;

const Image = styled.div`
  /* padding: 20px 20px 20px 0; */
  overflow: hidden;
  height: auto;
  display: flex;
  align-items: center;

  img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
    border-radius: 50%;

    /* SP */
    @media (max-width: ${mediaQueryTresholds.SP}px) {
      width: 50px;
      height: 50px;
    }
  }
`;

const Title = styled.h2`
  flex-basis: calc(100% - 90px);
  overflow: hidden;
  height: auto;
  display: flex;
  align-items: center;

  @media (max-width: ${mediaQueryTresholds.SP}px) {
    font-size: 1.1rem;
  }
`;

export const PostCard = ({ title, slug, cover }) => (
  <PostCardItem>
    <Article>
      <Link to={slug} itemProp="url">
        <Header>
          <Image>
            <img src={cover || ''} />
          </Image>
          <Title>
            <span itemProp="headline">{title || slug}</span>
          </Title>
        </Header>
      </Link>
    </Article>
  </PostCardItem>
);
