import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0 auto;
    padding: 0;
    font-family: -apple-system, "Segoe UI", "Helvetica Neue", "Hiragino Kaku Gothic ProN", メイリオ, meiryo, sans-serif;
    font-size: 15px;
    display: block;
    height: 100%;
  }

  a{
    -webkit-tap-highlight-color: transparent;
  }
`;

export default GlobalStyle;
