import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { navigationPath } from '../../../config';

const MenuIcon = styled.button`
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border: 0;
  margin: 0;
  padding: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  span {
    display: flex;
    align-items: inherit;
    justify-content: inherit;
    width: 100%;

    svg {
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 1.5rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
      color: rgba(0, 0, 0, 0.54);
    }
  }
`;

const NavWrapper = styled.nav`
  z-index: 1;
  outline: 0;
  position: absolute;
  top: 51px;
  right: 0px;
  background-color: #fff;
  opacity: 1;
  transform: none;
  transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform-origin: 0px 26px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  max-width: calc(100% - 32px);
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 5px;
  text-align: center;
`;

const NavList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const NavItem = styled.li`
  width: auto;
  overflow: hidden;
  font-size: 1rem;
  box-sizing: border-box;
  min-height: 2.5rem;
  line-height: 2.5rem;
  padding: 0.5rem 2.5rem;

  a {
    color: #555;
    text-transform: uppercase;
  }
`;

export const SpNav = () => {
  const [showNavMenu, setShowNavMenu] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    // SP用Dropdownメニューのblurイベントの発火がうまくいかないので、
    // useRefを利用し、メニューにフォーカスを当てる
    // https://tech-mr-myself.hatenablog.com/entry/2020/01/04/101542?utm_source=feed
    showNavMenu && menuRef.current.focus();
  }, [showNavMenu]);

  return (
    <>
      <MenuIcon
        onClick={() => {
          setShowNavMenu(!showNavMenu);
        }}
      >
        <span>
          <svg>
            <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
          </svg>
        </span>
      </MenuIcon>
      {showNavMenu && (
        <NavWrapper
          ref={menuRef}
          tabIndex={1}
          onBlur={() => setTimeout(() => setShowNavMenu(false), 100)}
        >
          <NavList>
            {navigationPath.map(menu => {
              return (
                <NavItem key={menu.title}>
                  <Link to={menu.path}>{menu.title}</Link>
                </NavItem>
              );
            })}
          </NavList>
        </NavWrapper>
      )}
    </>
  );
};
