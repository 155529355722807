type MediaQueryTresholdsType = {
  SP: number;
  PC: number;
};

type NavigationPathType = {
  path: '/' | '/tags' | '/readme';
  title: 'Blog' | 'Tags' | 'README';
}[];

const mediaQueryTresholds: MediaQueryTresholdsType = {
  SP: 600,
  PC: 1000
};

const infoTitle: string = 'lighthouse.log';

const navBarSize: MediaQueryTresholdsType = {
  SP: 50,
  PC: 60
};

const navigationPath: NavigationPathType = [
  { path: '/', title: 'Blog' },
  { path: '/tags', title: 'Tags' },
  { path: '/readme', title: 'README' }
];

export { mediaQueryTresholds, infoTitle, navBarSize, navigationPath };
