import React from 'react';
import Helmet from 'react-helmet';

export default ({
  pageUrl,
  isArticle,
  title,
  description,
  image,
  appID = null
}) => (
  <Helmet>
    {pageUrl && <meta property="og:url" content={pageUrl} />}
    {isArticle && <meta property="og:type" content={'article'} />}
    {title && <meta property="og:title" content={title} />}
    {description && <meta property="og:description" content={description} />}
    {image && <meta property="og:image" content={image} />}
    {/* TODO: Facebook App Id */}
    {appID && <meta property="fb:app_id" content={appID} />}
  </Helmet>
);
