import React from 'react';
import { Helmet } from 'react-helmet';

const Favicon: React.FC = () => {
  return (
    <Helmet>
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="#ffffff" />
      <meta name="apple-mobile-web-app-title" content="lighthouse-dev" />
      <link rel="apple-touch-icon" href="/icons/icon-48x48.png" sizes="48x48" />
      <link rel="apple-touch-icon" href="/icons/icon-72x72.png" sizes="72x72" />
      <link rel="apple-touch-icon" href="/icons/icon-96x96.png" sizes="96x96" />
      <link
        rel="apple-touch-icon"
        href="/icons/icon-144x144.png"
        sizes="144x144"
      />
      <link
        rel="apple-touch-icon"
        href="/icons/icon-196x196.png"
        sizes="196x196"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="48x48"
        href="/icons/icon-48x48.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/icons/icon-96x96.png"
      />
    </Helmet>
  );
};

export default Favicon;
