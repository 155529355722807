import React, { FC } from 'react';
import styled from 'styled-components';
import { mediaQueryTresholds } from '../../../config';

const TitleWrapper = styled.div`
  /* SP */
  @media screen and (max-width: ${mediaQueryTresholds.PC}px) {
    width: 90vw;
  }

  h1 {
    padding: 0;
    margin-bottom: 0;
    border-bottom: none;
    font-size: 2em;
  }

  p {
    color: #5f6368;
    font-size: 14px;
  }
`;

type Props = {
  postData: {
    title: string;
    date: string;
  };
};

export const Title: FC<Props> = ({ postData }) => (
  <TitleWrapper>
    {/* TODO: 記事ロゴを表示 */}
    <h1 itemProp="headline">{postData.title}</h1>
    <p>{postData.date}</p>
  </TitleWrapper>
);
