import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout } from '../components/PageLayout';
import { Title } from '../components/Article/Title';
import { Tag } from '../components/Article/Tag';
import { Body } from '../components/Article/Body';
import { Toc } from '../components/Article/Toc';
import { mediaQueryTresholds } from '../config';

const Article = styled.article`
  /* PC */
  @media (min-width: ${mediaQueryTresholds.PC}px) {
    padding: 25px 100px;
  }
`;

const Header = styled.header`
  /* SP */
  @media (max-width: ${mediaQueryTresholds.SP}px) {
    padding: 0 20px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  height: 100%;
`;

const HEADER_OFFSET_Y = 80;

export default ({ data }) => {
  const post = data.markdownRemark;
  const [currentHeaderUrl, setCurrentHeaderUrl] = useState(undefined);

  useEffect(() => {
    const handleScroll = () => {
      let aboveHeaderUrl;
      const currentOffsetY = window.pageYOffset;
      const headerElements: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(
        '.anchor-header'
      );

      for (const elem of headerElements) {
        const { top } = elem.getBoundingClientRect();
        const elemTop = top + currentOffsetY;
        const isLast = elem === headerElements[headerElements.length - 1];

        if (currentOffsetY < elemTop - HEADER_OFFSET_Y) {
          aboveHeaderUrl &&
            setCurrentHeaderUrl(aboveHeaderUrl.split(location.origin)[1]);
          !aboveHeaderUrl && setCurrentHeaderUrl(undefined);
          break;
        } else {
          isLast && setCurrentHeaderUrl(elem.href.split(location.origin)[1]);
          !isLast && (aboveHeaderUrl = elem.href);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const seoProps = {
    title: post.frontmatter.title,
    description: post.frontmatter.description || post.excerpt || 'nothin',
    image: post.frontmatter.cover.childImageSharp.sizes.src
  };

  return (
    <>
      <Layout seoProps={seoProps}>
        <Article itemScope itemType="http://schema.org/Article">
          <Header>
            <Title postData={post.frontmatter} />
            <Tag tags={post.frontmatter.tags} />
          </Header>
          <BodyWrapper className="markdown-body">
            <Body postHtml={post.html} />
            <Toc
              tableOfContents={post.tableOfContents}
              currentHeaderUrl={currentHeaderUrl}
            />
          </BodyWrapper>
        </Article>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        description
        tags
        cover {
          childImageSharp {
            sizes {
              src
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
