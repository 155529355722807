import React from 'react';
import { Link, graphql } from 'gatsby';
// Utilities
import kebabCase from 'lodash/kebabCase';
import styled from 'styled-components';
import { Layout } from '../../components/PageLayout';
import { mediaQueryTresholds } from '../../config';

const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  a {
    text-decoration: none;
  }
`;

const Label = styled.li`
  display: block;
  transition: 0.5s;
  margin-right: 13px;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: rgba(209, 209, 209, 0.3);
  border-radius: 18px;
  font-size: 1.2em;
  font-weight: 500;
  color: #333;
  cursor: pointer;

  /* SP */
  @media (max-width: ${mediaQueryTresholds.SP}px) {
    padding: 8px 15px;
    margin-right: 10px;
    margin-bottom: 15px;
  }
`;

export default ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title }
    }
  }
}) => {
  group.sort((a, b) => {
    return a.totalCount > b.totalCount
      ? -1
      : a.totalCount < b.totalCoun
      ? 1
      : 0;
  });

  const seoProps = { title };

  return (
    <Layout seoProps={seoProps}>
      <TagList>
        {group.map(tag => (
          <Link key={tag.fieldValue} to={`/tags/${kebabCase(tag.fieldValue)}/`}>
            <Label>
              {tag.fieldValue} ({tag.totalCount})
            </Label>
          </Link>
        ))}
      </TagList>
    </Layout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
