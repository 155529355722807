import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { mediaQueryTresholds } from '../../../config';
import { ScreenContext } from '../../PageLayout/Layout';

const PcTocWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${mediaQueryTresholds.PC}px) {
    // sticky 속성을 사용해서 화면에 계속 머무르도록 한다
    // sticky는 fixed와 static을 결합한 형태라고 생각하면 됨.
    position: sticky;
    display: block;
    flex-basis: 30%;
    top: 90px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px 0;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgb(67 133 187 / 7%);
    min-width: 300px;
    max-width: 300px;
    margin-left: 20px;
    font-family: -apple-system, 'Segoe UI', 'Helvetica Neue',
      'Hiragino Kaku Gothic ProN', メイリオ, meiryo, sans-serif;
  }
`;

const SpTocWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-height: 70vh;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 -5px 20px rgb(0 27 68 / 23%);
  border-radius: 5px;
  z-index: 3;
  padding: 10px;
`;

const SpTocButton = styled.button`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  padding: 8px;
  overflow: hidden;
  border: 1px solid #dadce0;
  border-radius: 5px;
  background-color: #fff;
  opacity: 0.9;
`;

const TocHtml = styled.section`
  ul {
    list-style: none;
    padding-left: 25px;
    padding-right: 10px;

    li {
      margin: 10px 0;

      p {
        margin: 10px 0;
      }
      a {
        display: block;
        font-size: 15px;
        color: #77838c;
      }
      a.isCurrent {
        transform: scale(1.08);
        transition: all 1s ease-out;
        color: #505050;
        font-weight: 800;
      }
      a.isCurrent::before {
        position: absolute;
        top: 50%;
        left: -7px;
        display: block;
        content: '';
        width: 3px;
        height: 100%;
        background-color: #007aff;
        transform: translateY(-50%);
      }
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  visibility: visible;
`;

export const Toc = ({ tableOfContents, currentHeaderUrl }) => {
  const isSmartPhone = useContext(ScreenContext);
  const [isShowSpToc, setIsShowSpToc] = useState<boolean>(false);

  const replaceItems = useMemo(() => {
    if (currentHeaderUrl) {
      return tableOfContents.replace(
        `"${currentHeaderUrl}"`,
        `"${currentHeaderUrl}" class="isCurrent"`
      );
    } else {
      return tableOfContents;
    }
  }, [currentHeaderUrl]);

  const handelToc = () => {
    setIsShowSpToc(!isShowSpToc);
  };

  return (
    <>
      {isSmartPhone ? (
        <>
          {isShowSpToc && (
            <>
              <SpTocWrapper>
                <TocHtml
                  onClick={handelToc}
                  dangerouslySetInnerHTML={{ __html: replaceItems }}
                />
              </SpTocWrapper>
              <Overlay onClick={handelToc} />
            </>
          )}
          <SpTocButton onClick={handelToc}>
            <svg viewBox="0 0 377 377" width="22" height="22">
              <rect x="75" y="73.5" width="250" height="30" />
              <rect y="73.5" width="30" height="30" />
              <rect y="273.5" width="30" height="30" />
              <rect x="75" y="273.5" width="250" height="30" />
              <rect y="173.5" width="30" height="30" />
              <rect x="75" y="173.5" width="250" height="30" />
            </svg>
          </SpTocButton>
        </>
      ) : (
        <PcTocWrapper>
          <TocHtml dangerouslySetInnerHTML={{ __html: replaceItems }} />
        </PcTocWrapper>
      )}
    </>
  );
};
