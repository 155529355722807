import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout } from '../components/PageLayout';
import { PostCard } from '../components/PostCard';

const PostContainer = styled.ol`
  max-width: 1200px;
  min-width: 60vw;
  height: 100%;
  margin: 0;
  padding: 0;
`;

const TagTitle = styled.h1`
  text-align: center;
  font-size: 2.5em;
`;

export default ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagHeader = `#${tag} (${totalCount})`;

  return (
    <Layout>
      <PostContainer>
        <TagTitle>{tagHeader}</TagTitle>
        {edges.map(({ node }) => {
          const { slug } = node.fields;
          const { title } = node.frontmatter;

          return (
            <PostCard
              key={slug}
              title={title}
              slug={slug}
              cover={node.frontmatter.cover?.childImageSharp?.resize.src}
            />
          );
        })}
      </PostContainer>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            cover {
              childImageSharp {
                resize(width: 70) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
