import React, { useContext } from 'react';
import { ScreenContext } from '..';
import { PcNav } from './PcNav';
import { SpNav } from './SpNav';

export const Navigation = () => {
  const isSmartPhone = useContext(ScreenContext);

  if (isSmartPhone === null) {
    return null;
  }

  return isSmartPhone ? <SpNav /> : <PcNav />;
};
