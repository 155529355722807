// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-readme-index-tsx": () => import("./../../../src/pages/readme/index.tsx" /* webpackChunkName: "component---src-pages-readme-index-tsx" */),
  "component---src-pages-tags-index-tsx": () => import("./../../../src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/postTemplate.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/tagTemplate.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */)
}

