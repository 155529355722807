import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import avatarImage from '../../../images/avatar.jpg';
import { Navigation } from '../Navigation';
import { infoTitle, mediaQueryTresholds } from '../../../config';

const HeaderWrapper = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  z-index: 100;
  border-bottom: 1px solid #dedede;
  background-color: #fff;
  padding: 0 10px;

  /* SP */
  @media (max-width: ${mediaQueryTresholds.SP}px) {
    height: 50px;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
  }
`;

const InfoTitle = styled.h1`
  flex-grow: 1;
  padding: 0 10px;
  color: #555555;
  font-size: 1.3rem;

  /* SP */
  @media (max-width: ${mediaQueryTresholds.SP}px) {
    font-size: 1.2rem;
  }
`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const Header = () => {
  return (
    <HeaderWrapper>
      <Link to="/" className="avatarLink">
        <Avatar>
          <img alt={infoTitle} src={avatarImage} />
        </Avatar>
        <InfoTitle>{infoTitle}</InfoTitle>
      </Link>
      <Navigation />
    </HeaderWrapper>
  );
};
