import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../../components/PageLayout';
import { BodyHtml, BodyWrapper } from '../../components/Article/Body';

export default ({ data }) => {
  const readmeHtml = data?.allMarkdownRemark?.nodes[0]?.html;

  return (
    <Layout>
      <BodyWrapper>
        <BodyHtml
          className="markdown-body"
          dangerouslySetInnerHTML={{ __html: readmeHtml }}
          itemProp="articleBody"
        />
      </BodyWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { tags: { eq: null } } }) {
      nodes {
        excerpt
        html
      }
    }
  }
`;
