import React, { FC } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import kebabCase from 'lodash/kebabCase';

const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0 !important;

  a {
    text-decoration: none;
  }
  a:hover {
    font-weight: 600;
    font-size: 17px;
    text-decoration: none;
  }
`;

const TagLabel = styled.li`
  background-color: #fff;
  border-radius: 20px;
  border: solid #dfdfdf 1px;
  box-sizing: border-box;
  color: #202124;
  height: 30px;
  line-height: 30px;
  margin-right: 5px;
  overflow: hidden;
  padding: 0 15px;
  transition: 0.5s;
  cursor: pointer;
`;

type Props = {
  tags: string[];
};

export const Tag: FC<Props> = ({ tags }) => (
  <TagList>
    {tags.map(tag => {
      return (
        <Link key={tag} to={`/tags/${kebabCase(tag)}/`}>
          <TagLabel>{tag}</TagLabel>
        </Link>
      );
    })}
  </TagList>
);
