import React from 'react';
import styled from 'styled-components';
import 'github-markdown-css';
import 'prismjs/themes/prism-okaidia.css';
import { mediaQueryTresholds } from '../../../config';

export const BodyWrapper = styled.section`
  margin: 0 auto;
  background-color: #fff;
  width: 90vw;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgb(67 133 187 / 7%);

  /* SP */
  @media (max-width: ${mediaQueryTresholds.SP}px) {
    width: 100vw;
    border-radius: unset;
    padding-bottom: 50px;
  }

  /* PC */
  @media screen and (min-width: ${mediaQueryTresholds.PC}px) {
    max-width: 60vw;
  }

  hr {
    height: 1px;
  }
`;

export const BodyHtml = styled.article`
  font-family: -apple-system, 'Segoe UI', 'Helvetica Neue',
    'Hiragino Kaku Gothic ProN', メイリオ, meiryo, sans-serif;

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.8em;
  }
  h2:first-child {
    margin-top: 0 !important;
  }
  h3 {
    font-size: 1.6em;
  }
  h4 {
    font-size: 1.3em;
  }
  h5 {
    font-size: 1.15em;
  }
  h6 {
    font-size: 1em;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 3rem;
    scroll-margin-top: 70px;
  }

  li,
  li + li,
  li > p {
    margin-top: 0.5em;
    margin-bottom: 0;
  }

  .language-text {
    background: rgba(33, 90, 160, 0.07);
    text-shadow: none;
    color: #333;
    padding: 0.2em 0.5em;
    border-radius: 5px;
  }

  .gatsby-highlight-code-line {
    background-color: #4b4b4b;
    display: block;
    margin-right: -1em;
    margin-left: -1em;
    padding-right: 1em;
    padding-left: 1em;
  }

  .gatsby-remark-code-title {
    background: #d73a49;
    color: white;
    margin: 3rem -20px -1px -20px;
    padding: 10px;
    font-size: 0.9em;
    height: 10px;
    line-height: 10px;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
      Courier New, monospace;
    display: table;
  }

  .gatsby-highlight {
    margin-bottom: 1.5rem;

    pre {
      margin: 0 -20px;
      border-radius: 0;
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
        Courier New, monospace;
    }
  }
`;

export const Body = ({ postHtml }) => (
  <BodyWrapper>
    <BodyHtml
      dangerouslySetInnerHTML={{ __html: postHtml }}
      itemProp="articleBody"
    />
    {/* <hr /> */}
    {/* TODO: コメント機能 
        https://github.com/blueshw/gatsby-blog/blob/c6ca098066946727e4c100adf530aa40bb7a7713/src/templates/blog-post.js#L99
    */}
    {/* TODO: Footer実装 */}
    {/* <footer>article footer</footer> */}
  </BodyWrapper>
);
